import { Collapsible } from "@materializecss/materialize";
import { elementParents, elementsOnEvent } from "../../shared/jquery-alternative";
import "../../../../vendor/assets/javascripts/spree/frontend/cart"
document.addEventListener('turbo:load', function() {
  if (document.querySelector("#guest.btn")) {
    document.querySelector('#guest.btn').addEventListener('click', function(e) {
      e.preventDefault();
      document.querySelector('#guest_user.login-boxs').style.display = 'block';
      Collapsible.init(document.querySelectorAll(".collapsible"))
    });
  }

  const changeOrderDeliveryOption = (delivery_mode, url) => fetch(url, {
    type: "PUT",
    headers: {"Authorization": `Bearer ${ Rails.access_token }`},
    body: JSON.stringify({
      delivery_mode
    })
  });

  if (document.querySelectorAll(".collapsible").length > 0) {
    const elems = document.querySelectorAll(".collapsible");
    Collapsible.init(elems);
  }

  if(document.querySelector(".small-image")) {
    const smallImageEl = document.querySelector(".small-image")
    smallImageEl.addEventListener("mouseenter", function(e) {
      const parents = elementParents(this, '.parent-placeholder');
      if(parents.length) {
        parent[0].querySelector('.large-image').style.display = "";
      }
      
    })
    smallImageEl.addEventListener("mouseleave", function(e) {
      const parents = elementParents(this, '.parent-placeholder');
      if(parents.length) {
        parent[0].querySelector('.large-image').style.display = "none";
      }
    });
  }

  const orders = {};
  const withStoreOrder = function(store_id, onSuccess, onError) {
    if (!!orders[store_id]) {
      onSuccess(orders[store_id]);
      return;
    }
    fetch(`/api/v2/stores/${ store_id }/orders/current`, {
      headers: {"Authorization": `Bearer ${ Rails.access_token }`}
    }).then((order) => {
      orders[store_id] = order;
      onSuccess(orders[store_id]);
    }).catch((error) => {
      onError(error);
    });
  };
  const shop_line_items = {};
  elementsOnEvent('.add-to-store-cart', 'click', (e) => {
    e.preventDefault();
    const store_id = this.dataset.storeId;
    const variant_id = this.dataset.variantId;
    const me = this;

    if (shop_line_items[store_id] == null) { shop_line_items[store_id] = {}; }
    if (shop_line_items[store_id][variant_id] == null) { shop_line_items[store_id][variant_id] = 0; }
    let quantity = (shop_line_items[store_id][variant_id] += 1);
    me.querySelector('.quantity').innerHTML = `(${ quantity })`;

    const total_quantity = parseInt(document.querySelector('.badge.white-text.circle.red').innerHTML);
    document.querySelector('.badge.white-text.circle.red').innerHTML = total_quantity + 1;
    withStoreOrder(store_id,
      function(order) {
        const url = `/api/v2/orders/${ order.number }/line_items.json`;
        fetch(url, {
          method: 'POST',
          headers: {"Authorization": `Bearer ${ Rails.access_token }`},
          body: JSON.stringify({
            'line_item[weighted_quantity]': 1,
            'line_item[variant_id]': variant_id
          })
        }).catch((error) => {
          quantity = (shop_line_items[store_id][variant_id] -= 1);
          document.querySelector('.badge.white-text.circle.red').innerHTML = total_quantity;
          if (quantity < 1) {
            me.querySelector('.quantity').innerHTML = "";
          } else {
            me.querySelector('.quantity').innerHTML = `(${ quantity })`;
          }
        });
      },
      function(error) {
        quantity = (shop_line_items[store_id][variant_id] -= 1);
        document.querySelector('.badge.white-text.circle.red').innerHTML = total_quantity;
        if (quantity < 1) {
          me.querySelector('.quantity').innerHTML = "";
        } else {
          me.querySelector('.quantity').innerHTML = `(${ quantity })`;
        }
      });
  });
});
